import cn from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetXmlOrderQuery } from '../../../services/peppol'
import { useAppDispatch } from '../../../store'
import { addAlert } from '../../../store/alerts'
import styles from './index.module.css'
import { FileEarmarkXml } from '../../../components/svg/FileEarmarkXml'

const cx = cn.bind(styles)

type Props = {
  orderUuid: string
}

export const DownloadOrderXml = ({ orderUuid }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const ref = useRef<HTMLAnchorElement>(null)
  const [downloading, setDownloading] = useState(false)

  const { current } = ref

  const { data, error } = useGetXmlOrderQuery({ uuid: orderUuid }, { skip: !downloading })

  useEffect(() => {
    if (error !== undefined) {
      setDownloading(false)
      dispatch(addAlert({ type: 'error', translationId: 'feedback.error.generic' }))
    }
  }, [dispatch, error, t])

  useEffect(() => {
    if (data !== undefined && current !== null) {
      setDownloading(false)

      const blob = new Blob([data], { type: 'octet/stream' })
      const url = window.URL.createObjectURL(blob)

      current.href = url
      current.download = `${orderUuid}.xml`
      current.style.display = 'none'

      current.click()
      window.URL.revokeObjectURL(url)
    }
  }, [current, data])

  const startDownload = () => {
    setDownloading(true)
  }

  return (
    <>
      <a ref={ref} className={cx('hidden-trigger')} href="#/">
        {t('pages.incomingOrders.downloadOrderXml')}
      </a>

      <button
        className={cx('cta')}
        title={t('pages.incomingOrders.downloadOrderXml')}
        onClick={startDownload}
      >
        <FileEarmarkXml width={22} height={22} />
      </button>
    </>
  )
}
