import cn from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Card } from '../../components/Card'
import { Pagination } from '../../components/Pagination'
import { useGetOrdersQuery } from '../../services/peppol'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  selectCurrentPage,
  selectTableAsQueryParams,
  selectTotalPages,
  updateCurrentPage,
} from '../../store/tables'
import { IncomingOrdersFilters } from './Filters'
import { IncomingOrdersTableContent } from './TableContent'
import styles from './index.module.css'
import { OrderDetails } from './OrderDetails'

const cx = cn.bind(styles)

const incomingOrdersTable = { table: 'incomingOrders' } as const

export const IncomingOrders = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const queryParams = useAppSelector(selectTableAsQueryParams(incomingOrdersTable))
  const currentPage = useAppSelector(selectCurrentPage(incomingOrdersTable))
  const totalPages = useAppSelector(selectTotalPages(incomingOrdersTable))

  // @ts-expect-error
  const { data } = useGetOrdersQuery(queryParams)
  const totalItems = data?.['hydra:totalItems']

  const changePage = (newPage: number) => {
    dispatch(updateCurrentPage({ table: 'incomingOrders', page: newPage }))
  }

  return (
    <>
      <Card.Header>
        <Card.HeaderTitle>{t('pages.incomingOrders.title')}</Card.HeaderTitle>
      </Card.Header>

      <IncomingOrdersFilters />

      <div className={cx('table-nav')}>
        {totalItems !== null && t('pages.incomingOrders.totalItems', { count: totalItems })}

        <div className={cx('pagination', { 'pagination--hidden': totalPages <= 1 })}>
          <Pagination current={currentPage} total={totalPages} onChange={changePage} />
        </div>
      </div>

      <div className={cx('table-responsive')}>
        <table className={cx('table')}>
          <thead className={cx('thead')}>
            <tr>
              <th>{t('pages.incomingOrders.table.header.createdAt')}</th>
              <th>{t('pages.incomingOrders.table.header.senderName')}</th>
              <th>{t('pages.incomingOrders.table.header.date')}</th>
              <th>{t('pages.incomingOrders.table.header.number')}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <IncomingOrdersTableContent />
          </tbody>
        </table>
      </div>

      <OrderDetails />

      <div className={cx('table-nav')}>
        {totalItems !== null && t('pages.incomingOrders.totalItems', { count: totalItems })}

        <div className={cx('pagination', { 'pagination--hidden': totalPages <= 1 })}>
          <Pagination current={currentPage} total={totalPages} onChange={changePage} />
        </div>
      </div>
    </>
  )
}
